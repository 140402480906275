import { createSlice } from '@reduxjs/toolkit';
import { getAuctions } from '@actions/auctions';

export const auctionsSlice = createSlice({
  name: 'auctions',
  initialState: {
    list: [],
  },
  extraReducers: {
    [getAuctions.fulfilled]: (state, action) => ({
      list: action.payload.data.data,
      total: action.payload.data.total
    }),
  },
});

export default auctionsSlice.reducer;
