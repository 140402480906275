import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '@api/instance';
import {
  USERS_LIST,
  USER_BY_UUID,
  USER_CHANGE_STATUS,
  AUCTIONS_FOR_USER,
  USER_BLOCK,
  USER_UNBLOCK,
  USER_UNLOCK_SUSPENDED,
  USERS_EXPORT,
  USER_SOFT_DELETE,
  USER_HARD_DELETE,
  USER_RESTORE,
  USER_TRANSACTIONS_LIST,
} from 'api/urls';

export const getUsers = createAsyncThunk('users/list', async (filters, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(USERS_LIST, filters, { headers: { token } });

  return { data: response.data };
});

export const exportUsers = createAsyncThunk('users/export', async (filters, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(USERS_EXPORT, filters, { headers: { token } });

  return { data: response.data };
});

export const getUserByUuid = createAsyncThunk('users/by-uuid', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.get(`${USER_BY_UUID}${data.userUuid}`, { headers: { token } });

  return { data: response.data };
});

export const changeUserStatus = createAsyncThunk('users/change-status', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(USER_CHANGE_STATUS, data, { headers: { token } });

  return { data: response.data };
});

export const getAuctionsForUser = createAsyncThunk('users/auctions-list', async ({ userUuid, statusUuid, ...data }, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(AUCTIONS_FOR_USER.replace('{uuid}', userUuid), (statusUuid ? { status_uuid: statusUuid } : {}), { params: data, headers: { token } });

  return { data: response.data };
});

export const blockUser = createAsyncThunk('users/block', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(USER_BLOCK, data, { headers: { token } });

  return { data: response.data };
});

export const unblockUser = createAsyncThunk('users/unblock', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(USER_UNBLOCK, data, { headers: { token } });

  return { data: response.data };
});

export const unlockSuspendedUser = createAsyncThunk('users/unlocksuspended', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(USER_UNLOCK_SUSPENDED, data, { headers: { token } });

  return { data: response.data };
});

export const hardDeleteUser = createAsyncThunk('users/hard-delete', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.delete(USER_HARD_DELETE, { data, headers: { token } });

  return { data: response.data };
});

export const softDeleteUser = createAsyncThunk('users/soft-delete', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.delete(USER_SOFT_DELETE, { data, headers: { token } });

  return { data: response.data };
});

export const restoreUser = createAsyncThunk('users/restore', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(USER_RESTORE, data, { headers: { token } });

  return { data: response.data };
});

export const getTransactionsForUser = createAsyncThunk('users/transactions-list', async ({ userUuid, pagination }, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(USER_TRANSACTIONS_LIST.replace('{uuid}', userUuid), pagination, { headers: { token } });

  return { data: response.data };
});
