export const BASE_URL = process.env.BASE_URL_API || 'https://api.junkacar.in-aim.dev/api/';

export const IMAGE_URL = process.env.BASE_URL_IMAGE || 'https://api.junkacar.in-aim.dev';

const FIREBASE_KEY = process.env.FIREBASE_KEY || 'AIzaSyBLj7YvvVlqLi-RnT2BIDh_A5e__DdAJck';

export const FIREBASE_SIGN_IN = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${FIREBASE_KEY}`;
export const FIREBASE_REFRESH_TOKEN = `https://securetoken.googleapis.com/v1/token?key=${FIREBASE_KEY}`;

export const DICTIONARY = 'dictionary/all';

export const DASHBOARD = 'supervisor/dashboard';

export const PAGE = 'supervisor/page';
export const PAGE_LIST = 'page/list';
export const PAGE_ITEM = 'supervisor/page/'; // + uuid

export const USERS_LIST = 'supervisor/user/list';
export const USERS_EXPORT = 'supervisor/user/list/export';
export const USER_BY_UUID = 'supervisor/user/'; // + uuid
export const USER_CHANGE_STATUS = 'supervisor/user/changeStatus';
export const AUCTIONS_FOR_USER = 'supervisor/user/{uuid}/auctions';

export const AUCTIONS_LIST = 'supervisor/auction';
export const AUCTIONS_EXPORT = 'supervisor/auction/export';
export const AUCTION_GET = 'supervisor/auction/'; // + uuid
export const AUCTION_CANCEL = 'supervisor/auction/cancel';
export const AUCTION_BIDDINGS = 'supervisor/auction/{uuid}/bids';
export const AUCTION_REFUND = 'supervisor/incident/approve';
export const AUCTION_MESSAGES = 'supervisor/chat/'; // + uuid

export const USER_BLOCK = 'supervisor/user/block';
export const USER_UNBLOCK = 'supervisor/user/unlockBlocked';
export const USER_UNLOCK_SUSPENDED = 'supervisor/user/unlockSuspended';
export const USER_SOFT_DELETE = 'supervisor/user/delete/soft';
export const USER_HARD_DELETE = 'supervisor/user/delete/hard';
export const USER_RESTORE = 'supervisor/user/restore';
export const USER_TRANSACTIONS_LIST = 'supervisor/user/{uuid}/getTransactionHistory';
