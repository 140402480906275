import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F6D421',
    },
    secondary: {
      main: '#6C757D',
    },
    error: {
      main: '#DC3545',
    },
    warning: {
      main: '#FFC107',
    },
    info: {
      main: '#17A2B8',
    },
    success: {
      main: '#28A745',
    },
    light: '#F8F9FA',
    dark: '#343A40',
    white: '#FFFFFF',
    border: '#DFDFDF',
    image: {
      fill: '#868E96',
      border: '#DEE2E6',
    },
    text: {
      body1: '#212529',
      body2: '#FFFFFF80',
      muted: '#6C757D',
      primary: '#212529',
      secondary: '#00000080',
    },
  },
  shadows: [
    'none',
    '0px 2px 4px rgba(0, 0, 0, 0.075)',
    '0px 4px 16px rgba(0, 0, 0, 0.08)',
    '0px 16px 48px rgba(0, 0, 0, 0.176)',
  ],
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontSize: 40,
      color: '#343A40',
    },
    h2: {
      fontSize: 32,
      color: '#343A40',
    },
    h3: {
      fontSize: 28,
      color: '#212529',
    },
    h4: {
      fontSize: 24,
      color: '#C4C4C4',
    },
    h5: {
      fontSize: 20,
      color: '#212529',
    },
    h6: {
      fontSize: 16,
      color: '#343A40',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 12.8,
      color: '#00000080',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
    MuiButton: {
      containedSecondary: {
        backgroundColor: '#343A40',
      },
    },
    MuiSelect: {
      select: {
        backgroundColor: '#FFFFFF',
        width: '100%',
      },
    },
    MuiAlert: {
      message: {
        padding: 0,
        fontSize: '16px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
    },
    MuiMenuItem: {
      root: {
        textTransform: 'initial',
      },
    },
  },
});

export default theme;
