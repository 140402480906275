import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '@api/instance';
import { AUCTIONS_LIST, AUCTIONS_EXPORT, AUCTION_CANCEL, AUCTION_GET } from '@api/urls';
import { AUCTION_BIDDINGS, AUCTION_MESSAGES, AUCTION_REFUND } from 'api/urls';

export const getAuctions = createAsyncThunk('auctions/list', async (filters, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(AUCTIONS_LIST, filters, { headers: { token } });

  return { data: response.data };
});

export const exportAuctions = createAsyncThunk('auctions/export', async (filters, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(AUCTIONS_EXPORT, filters, { headers: { token } });

  return { data: response.data };
});

export const getAuction = createAsyncThunk('auctions/get', async (uuid, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.get(`${AUCTION_GET}${uuid}`, { headers: { token } });

  return { data: response.data };
});

export const cancelAuction = createAsyncThunk('auctions/cancel', async (data, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(AUCTION_CANCEL, data, { headers: { token } });

  return { data: response.data };
});

export const getBiddingsForAuction = createAsyncThunk('auctions/cancel', async ({ auctionUuid, ...params }, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.get(AUCTION_BIDDINGS.replace('{uuid}', auctionUuid), { headers: { token }, params });

  return { data: response.data };
});

export const approveRefund = createAsyncThunk('auctions/refund', async (incidentUuid, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.post(AUCTION_REFUND, { incidentUuid }, { headers: { token } });

  return { data: response.data };
});

export const getMessages = createAsyncThunk('auctions/messages', async ({ chatUuid, ...params }, thunkApi) => {
  try {
    const token = thunkApi.getState().user.idToken;
    const response = await instance.get(`${AUCTION_MESSAGES}${chatUuid}`, { headers: { token }, params });

    return { data: response.data };
  } catch (error) {
    return thunkApi.rejectWithValue({
      data: error.response.data,
    });
  }
});
