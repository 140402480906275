import { createSlice } from '@reduxjs/toolkit';
import { getDictionaries } from '@actions/dictionary';

export const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState: {},
  extraReducers: {
    [getDictionaries.fulfilled]: (state, action) => ({
      ...action.payload.data,
    }),
  },
});

export default dictionariesSlice.reducer;
