import React, {
  useCallback,
  useContext,
  createContext,
  useState,
} from 'react';
import { ConfirmModalBody } from '@components/organisms/confirm-modal/confirm-modal-body';

const ConfirmModalContext = createContext();

export const ConfirmModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({});

  const openDialog = useCallback(({ message, callback }) => {
    setOpen(true);
    setConfig({ message, callback });
  }, [setOpen, setConfig]);

  return (
    <ConfirmModalContext.Provider value={openDialog}>
      <ConfirmModalBody
        open={open}
        onOk={() => {
          setOpen(false);
          config.callback(true);
        }}
        onClose={() => {
          setOpen(false);
          config.callback(false);
        }}
        message={config.message}
      />
      { children }
    </ConfirmModalContext.Provider>
  );
};

export const useConfirmationDialog = () => {
  const openDialog = useContext(ConfirmModalContext);

  const confirm = (message) => new Promise((resolve) => {
    openDialog({ callback: resolve, message });
  });

  return { confirm };
};
