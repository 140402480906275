import { createSlice } from '@reduxjs/toolkit';
import { signIn, getNewToken } from '@actions/user';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    auth: false,
  },
  reducers: {
    signOut: () => ({ auth: false }),
  },
  extraReducers: {
    [signIn.fulfilled]: (state, action) => ({
      auth: true,
      ...action.payload.data,
    }),
    [getNewToken.fulfilled]: (state, action) => ({
      ...state,
      auth: true,
      expiresIn: action.payload.data.expires_in,
      idToken: action.payload.data.id_token,
      refreshToken: action.payload.data.refresh_token,
    }),
  },
});

export const { signOut } = userSlice.actions;

export default userSlice.reducer;
