import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '@api/instance';
import { DICTIONARY } from '@api/urls';

export const getDictionaries = createAsyncThunk('dictionary/list', async (_, thunkApi) => {
  const token = thunkApi.getState().user.idToken;
  const response = await instance.get(DICTIONARY, { headers: { token } });

  return { data: response.data };
});
