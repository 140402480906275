import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { instance } from '@api/instance';
import { FIREBASE_SIGN_IN, FIREBASE_REFRESH_TOKEN } from '@api/urls';

export const signIn = createAsyncThunk('user/signIn', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post(FIREBASE_SIGN_IN, { returnSecureToken: true, ...data });

    localStorage.setItem('refreshToken', response.data.refreshToken);
    return { data: response.data };
  } catch (error) {
    return rejectWithValue({
      data: error.response.data,
    });
  }
});

export const getNewToken = createAsyncThunk('user/refreshToken', async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await axios.post(FIREBASE_REFRESH_TOKEN, { grant_type: 'refresh_token', refresh_token: refreshToken });
  localStorage.setItem('refreshToken', response.data.refresh_token);

  return { data: response.data };
});
