import { createSlice } from '@reduxjs/toolkit';
import { getUsers, changeUserStatus } from '@actions/users';

export const usersSlice = createSlice({
  name: 'user',
  initialState: {
    list: [],
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => ({
      list: action.payload.data.data,
      total: action.payload.data.total,
    }),
    [changeUserStatus.fulfilled]: (state, action) => {
      (state.list.find(
        (user) => user.email === action.meta.arg.login,
      ) || {}
      ).user_status_uuid = action.meta.arg.status_uuid;
    },
  },
});

export default usersSlice.reducer;
