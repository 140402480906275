import React, {
  Suspense, lazy, useEffect, useCallback, useState,
} from 'react';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '@components/organisms/loading';
import { getNewToken } from '@actions/user';
import { getDictionaries } from 'store/actions/dictionary';
import './styles/main.scss';

const Index = lazy(() => import('./pages/index'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const Users = lazy(() => import('./pages/users'));
const Content = lazy(() => import('./pages/content'));
const UserData = lazy(() => import('./pages/user-data'));
const Auctions = lazy(() => import('./pages/auctions'));
const AuctionData = lazy(() => import('./pages/auction-data'));

const App = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const userInAuth = useSelector((state) => state.user.auth);

  const [loading, setLoading] = useState(true);

  const onRefreshToken = useCallback(() => {
    dispatch(getNewToken()).then((res) => {
      setLoading(false);

      if (res.meta.requestStatus === 'fulfilled') {
        setTimeout(() => {
          onRefreshToken();
        }, res.payload.data.expires_in * 1000);
      }
    });
  }, []);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      onRefreshToken();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (userInAuth) {
        if (history.location.pathname === '/') {
          history.replace('/dashboard');
        }
      } else if (history.location.pathname !== '/') {
        history.replace('/');
      }
    }
  }, [userInAuth, history, loading]);

  useEffect(() => {
    dispatch(getDictionaries());
  }, []);

  if (loading) {
    return (<Loading />);
  }

  return (
    <Suspense fallback={<Loading allPage />}>
      <Switch>
        {!userInAuth ? (
          <Route path="/" component={Index} />
        ) : (
          <>
            <Route path="/dashboard" component={Dashboard} />
            <Route exact path="/users" component={Users} />
            <Route path="/users/:uuid" component={UserData} />

            <Route exact path="/auctions" component={Auctions} />
            <Route path="/auctions/:uuid" component={AuctionData} />

            <Route path="/content" component={Content} />
          </>
        )}
      </Switch>
    </Suspense>
  );
};

export default App;
