import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user';
import usersReducer from './reducers/users';
import auctionsReducer from './reducers/auctions';
import dictionariesReducer from './reducers/dictionary';

export default configureStore({
  reducer: {
    user: userReducer,
    users: usersReducer,
    auctions: auctionsReducer,
    dictionaries: dictionariesReducer,
  },
});
