import React from 'react';
import {
  Modal, Box, Typography, Button, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    position: 'absolute',
    top: '59%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 4),
  },
  title: {
    fontWeight: '500',
    color: theme.palette.text.body1,
    marginBottom: theme.spacing(3),
  },
}));

export const ConfirmModalBody = ({
  open = false,
  onOk = () => {},
  onClose = () => {},
  message = 'Are you sure?',
}) => {
  const css = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box classes={{ root: css.main }}>
        <Typography variant="h2" classes={{ root: css.title }}>
          {message}
        </Typography>
        <Grid container>
          <Box mr={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
            >
              No
            </Button>
          </Box>
          <Button variant="contained" onClick={onOk}>
            Yes
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};
